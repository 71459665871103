import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row q-pa-md q-gutter-md" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "row q-pb-md" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "row q-gutter-md" }
const _hoisted_6 = { class: "col-auto" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "col-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLogo = _resolveComponent("AppLogo")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_VehicleReportAvailability = _resolveComponent("VehicleReportAvailability")!
  const _component_VehicleReportCanceledTable = _resolveComponent("VehicleReportCanceledTable")!
  const _component_VehicleReportStatusTable = _resolveComponent("VehicleReportStatusTable")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_q_card, {
                    square: "",
                    class: "q-pa-md flex items-center",
                    style: {"height":"100%"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AppLogo)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_VehicleReportAvailability)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_VehicleReportCanceledTable)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_VehicleReportStatusTable)
        ])
      ])
    ]),
    _: 1
  }))
}