
import QTable from 'quasar/src/components/table/QTable.js';
import { computed, defineComponent } from 'vue'

const data = [
  {
    vehicle_name: 'VGN-201',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-202',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-203',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-204',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-205',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-206',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-207',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-208',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-209',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-210',
    vehicle_type_name: 'X10p',
    in_traffic: false,
  },
  {
    vehicle_name: 'VGN-211',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-212',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-213',
    vehicle_type_name: 'X10p',
    in_traffic: false,
  },
  {
    vehicle_name: 'VGN-214',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-215',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-216',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-217',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-218',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-219',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-220',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-221',
    vehicle_type_name: 'X10p',
    in_traffic: false,
  },
  {
    vehicle_name: 'VGN-222',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-223',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-224',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-225',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-226',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-227',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-228',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-229',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-230',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-231',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-232',
    vehicle_type_name: 'X10p',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-233',
    vehicle_type_name: 'X10p',
    in_traffic: false,
  },
  {
    vehicle_name: 'VGN-801',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-802',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-803',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-804',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-805',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-806',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-807',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-808',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-809',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-810',
    vehicle_type_name: 'UBP',
    in_traffic: false,
  },
  {
    vehicle_name: 'VGN-811',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-812',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-813',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-814',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-815',
    vehicle_type_name: 'UBP',
    in_traffic: false,
  },
  {
    vehicle_name: 'VGN-816',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-817',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-818',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-819',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-820',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-821',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-822',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-823',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-824',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-825',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-826',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-827',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-828',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-829',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-830',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-831',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-832',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-833',
    vehicle_type_name: 'UBP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-121',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-122',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-123',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-124',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-125',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-126',
    vehicle_type_name: 'UBXP',
    in_traffic: false,
  },
  {
    vehicle_name: 'VGN-127',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-128',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-129',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-130',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-131',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-132',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-133',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-134',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-135',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-136',
    vehicle_type_name: 'UBXP',
    in_traffic: false,
  },
  {
    vehicle_name: 'VGN-137',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-138',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-139',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-140',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-141',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-142',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-143',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-144',
    vehicle_type_name: 'UBXP',
    in_traffic: false,
  },
  {
    vehicle_name: 'VGN-145',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-146',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-147',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-148',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-149',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-150',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-151',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-152',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
  {
    vehicle_name: 'VGN-153',
    vehicle_type_name: 'UBXP',
    in_traffic: true,
  },
]

export default defineComponent({
  name: 'VehicleReportStatusTable',

  setup() {
    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'X10p',
        label: 'Motorvagn X10p',
        field: 'X10p',
        sortable: true,
      },
      {
        align: 'left',
        name: 'UBP',
        label: 'Mellanvagn UBP',
        field: 'UBP',
        sortable: true,
      },
      {
        align: 'left',
        name: 'UBXP',
        label: 'Manövervagn UBXP',
        field: 'UBXP',
        sortable: true,
      },
    ]

    const rows = computed(() => {
      const types = data.reduce<{
        X10p: { vehicle_name: string; in_traffic: boolean }[]
        UBXP: { vehicle_name: string; in_traffic: boolean }[]
        UBP: { vehicle_name: string; in_traffic: boolean }[]
      }>(
        (acc, item) => {
          const typeName = item.vehicle_type_name as 'X10p' | 'UBXP' | 'UBP'
          acc[typeName].push({
            vehicle_name: item.vehicle_name,
            in_traffic: item.in_traffic,
          })

          return acc
        },
        { X10p: [], UBXP: [], UBP: [] }
      )

      const length = Math.max(
        types.UBP.length,
        types.UBXP.length,
        types.X10p.length
      )
      return new Array(length).fill(null).map((x, i) => {
        return {
          X10p: types.X10p[i],
          UBP: types.UBP[i],
          UBXP: types.UBXP[i],
        }
      })
    })

    return {
      columns,
      rows,
    }
  },
})
