
import QTable from 'quasar/src/components/table/QTable.js';
import { defineComponent } from 'vue'

const data = [
  {
    text: 'Fordon tillgängliga 05:45',
    count: 29,
  },
]

export default defineComponent({
  name: 'VehicleReportAvailability',

  setup() {
    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'text',
        label: 'Daglig sammanfattning',
        field: 'text',
        sortable: true,
        style: 'width: 330px',
      },
      {
        align: 'left',
        name: 'count',
        label: 'X10p',
        field: 'X10p',

        sortable: true,
      },
    ]

    return {
      columns,
      data,
    }
  },
})
