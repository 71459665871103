
import { defineComponent } from 'vue'
import VehicleReportCanceledTable from '@/components/vehicle-report/VehicleReportCanceledTable.vue'
import VehicleReportStatusTable from '@/components/vehicle-report/VehicleReportStatusTable.vue'
import VehicleReportAvailability from '@/components/vehicle-report/VehicleReportAvailability.vue'
import AppLogo from '@/components/AppLogo.vue'

export default defineComponent({
  name: 'VehicleReport',

  components: {
    AppLogo,
    VehicleReportCanceledTable,
    VehicleReportStatusTable,
    VehicleReportAvailability,
  },

  setup() {
    return {}
  },
})
