
import QTable from 'quasar/src/components/table/QTable.js';
import { defineComponent } from 'vue'

const data = [
  {
    vehicle_type_description: 'Motorvagn X10p',
    vehicle_type_total_count: 35,
    vehicle_type_withdrawn_count: 5,
    vehicle_type_available_count: 30,
    vehicles: [
      {
        vehicle_name: 202,
        withdrawn_place_name_short: 'M',
        withdrawn_reason: 'GTO',
      },
      {
        vehicle_name: 203,
        withdrawn_place_name_short: 'M',
        withdrawn_reason: 'Ingen motorström',
      },
      {
        vehicle_name: 223,
        withdrawn_place_name_short: 'M',
        withdrawn_reason: 'Chevron',
      },
      {
        vehicle_name: 232,
        withdrawn_place_name_short: 'M',
        withdrawn_reason: 'Boggirevision, tasslagerrevision',
      },
      {
        vehicle_name: 222,
        withdrawn_place_name_short: 'M',
        withdrawn_reason: 'Trak2, dåligt pådrag',
      },
    ],
  },
  {
    vehicle_type_description: 'Mellanvagn UBP',
    vehicle_type_total_count: 32,
    vehicle_type_withdrawn_count: 3,
    vehicle_type_available_count: 29,
    vehicles: [
      {
        vehicle_name: 811,
        withdrawn_place_name_short: 'Ö',
        withdrawn_reason: 'F080 pga felaktiga eldelar',
      },
      {
        vehicle_name: 829,
        withdrawn_place_name_short: 'V',
        withdrawn_reason: 'Svarv',
      },
      {
        vehicle_name: 817,
        withdrawn_place_name_short: 'M',
        withdrawn_reason: 'DörrFu',
      },
    ],
  },
  {
    vehicle_type_description: 'Manövervagn UBXP',
    vehicle_type_total_count: 34,
    vehicle_type_withdrawn_count: 4,
    vehicle_type_available_count: 30,
    vehicles: [
      {
        vehicle_name: 127,
        withdrawn_place_name_short: 'Ö',
        withdrawn_reason: 'Ingen PA/HDD, termostat. HL?\t',
      },
      {
        vehicle_name: 152,
        withdrawn_place_name_short: 'V',
        withdrawn_reason: 'Svarv',
      },
      {
        vehicle_name: 128,
        withdrawn_place_name_short: 'V',
        withdrawn_reason: 'Svarv',
      },
      {
        vehicle_name: 149,
        withdrawn_place_name_short: 'M',
        withdrawn_reason: 'KoppelRev',
      },
    ],
  },
]

export default defineComponent({
  name: 'VehicleReportCanceledTable',

  setup() {
    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'vehicle_name',
        label: 'Fordon',
        field: 'vehicle_name',
        sortable: true,
      },
      {
        align: 'left',
        name: 'withdrawn_place_name_short',
        label: 'Avställd plats',
        field: 'withdrawn_place_name_short',
        sortable: true,
      },
      {
        align: 'left',
        name: 'withdrawn_reason',
        label: 'Orsak till att inte tillgängliga',
        field: 'withdrawn_reason',
        sortable: true,
      },
    ]

    return {
      columns,
      data,
    }
  },
})
